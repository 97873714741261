<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
//import StarRating from "vue-star-rating";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";
//import {reactive} from "vue";
import { format } from "date-fns";

export default {
  page: {
    title: "Détails automatiques",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Détails automatiques",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "details",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      date: "",
      dateDepart: "",
      dateArrive: "",
      tableData: [],
      passagerId: [],
      dataUser: [],
      details: "",
      sortBy: "orderid",
      sortDesc: false,
      conducteur: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "passager", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      passager: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "prix", sortable: true, label: "Prix" },
        { key: "billingname", sortable: true, label: "Passagers" },
        { key: "statut", sortable: true, label: "Statut" },
      ],
      username: "",
      dataUserTable: [],
      avatar: "",
      tokenUser: JSON.parse(sessionStorage.getItem("user")).tokenSalt,
      idtrajet: "",
      fisrtname: "",
      lastname: "",
      genre: "",
      boite_postale: "",
      profession: "",
      birthday: "",
      adress: "",
      city: "",
      country: "",
      number_phone: "",
      email: "",
      password: "",
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      img: "",
      vue: false,
      visible: false,
      showModal: false,
      HistoriqueConducteur: false,
      HistoriquePassager: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",
      startLocation: {
        lat: 0,
        lng: 0,
      },
      coordinates: [],
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },

  async mounted() {
    //console.log("tokenUser:", this.tokenUser);
    this.idtrajet = this.$route.params.idtrajet;

    //Chargement des données
    console.log("Détails", this.idtrajet);
    console.log("Table", this.tableData);

    //"admin/trajets/activites/?id=" + this.idUser,
    const data = await apiRequest("GET", "trajet/?id=" + this.idtrajet, false);
    if (data && data.data) {
      console.log("Data", data.data);

      this.tableData = data.data;
      (this.date = new Date(this.tableData.dateHeureDepart).toLocaleString(
        "fr-FR"
      )),
        (this.dateDepart = format(
          new Date(this.tableData.dateHeureDepart),
          "hh:mm:ss"
        ));
      this.dateArrive = format(
        new Date(this.tableData.dateHeureArrive),
        "hh:mm:ss"
      );

      if (data.data.conducteur.userAvatarCode !== null) {
        this.allImage.avatar =
          `http://216.158.239.208:1337/admin/image/?codeImage=` +
          data.data.conducteur.userAvatarCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.avatar = require("@/assets/images/users/attente.png");
      }

      if (data.data.conducteur.vehicule.autoMotoPictureCode !== null) {
        this.allImage.permis =
          `http://216.158.239.208:1337/admin/image/?codeImage=` +
          data.data.conducteur.vehicule.autoMotoPictureCode +
          `&token=` +
          this.tokenUser;
      } else {
        this.allImage.permis = require("@/assets/images/users/attente.png");
      }
    }

    const Users = await apiRequest("GET", "admin/users", undefined, false);
    if (data && data.data) {
      //console.log("data.data:", data.data);
      const formattedTable = Users.data.map((user) => {
        var nom = user.lastname == "" ? "Nom" : user.lastname;
        var prenom = user.firstname == "" ? "Prénom" : user.firstname;
        return {
          id: user.id,
          username: nom + " " + prenom,
          firstname: user.firstname,
          lastname: user.lastname,
          pays: user.pays,
          tel: user.tel,
          trajet: user.nombreTrajet,
          dateIns: new Date(user.dateCreation).toLocaleString("fr-FR"),
          badge: user.userValidated,
          statut: user.active ? "Actif" : "Suspendu",
        };
      });
      this.dataUser = formattedTable;
      console.log("data", Users.data);
    }

    var lespassagers = data.data.passagers;
    console.log("Table", lespassagers);

    this.passagerId = [];
    lespassagers.forEach((element) => {
      if (element.statut == "ACTIF") {
        this.passagerId.push(element.idClient);
      }
    });

    for (let i = 0; i < this.passagerId.length; i++) {
      const Users = await apiRequest(
        "POST",
        "admin/detail-user",
        { id: this.passagerId[i] },
        false
      );

      if (Users && Users.data) {
        console.log("detail Users", Users.data);
        this.dataUserTable.push(Users.data);
        console.log("dataUserTable", this.dataUserTable);
        //this.username = Users.data.personne.lastname+" "+ Users.data.personne.firstname
      }
    }
    this.dataMap();
  },
  methods: {
    async dataMap() {
      const data = await apiRequest(
        "GET",
        "admin/matching?idTrajet=" + this.idtrajet,
        false
      );
      if (data && data.data) {
        var all = new Array();
        data.data.map((element) => {
          if (element.data) {
            element.data.forEach((elementc) => {
              var coordinat = {
                name: element.user,
                lat: elementc.latitude,
                lng: elementc.longitude,
              };
              all.push(coordinat);
            });
            this.startLocation.lat = all[0].lat;
            this.startLocation.lng = all[0].lng;
          }
        });
        console.log("all:", all);
        this.coordinates = all;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    verification: function() {
      console.log("ok");
    },

    ValidationStatus: function(validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    Submit: function() {
      this.$v.$touch();
      if (this.$v.$pendding || this.$v.$error) return;
      alert("data submit");
    },
    showImg(index) {
      this.index = index;
      this.visible = true;
    },

    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },

    handleHide() {
      this.visible = false;
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    show() {
      this.visible = true;
    },
    getPosition: function(marker) {
      return {
        lat: parseFloat(marker.lat),
        lng: parseFloat(marker.lng),
      };
    },
    toggleInfo: function(marker, key) {
      console.log("marker:", marker);
      this.infoPosition = this.getPosition(marker);
      this.infoContent = marker.name;
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-center"></div>
            <div class="row py-5">
              <div class="col-md-4">
                <div class="row">
                 <div class="col-md-6">
                      <a
                    class="image-popup-vertical-fit d-flex justify-content-center"
                    @click="showImage"
                  >
                    <!-- <img
                      style="cursor: pointer;"
                      class="avatar-xl mt-5"
                      :src="`${this.allImage.permis}`"
                      data-holder-rendered="true"
                    /> -->
                    <img
                      style="cursor: pointer;"
                      class="avatar-xl mt-5"
                      src="require(`@/assets/small/img-1.jpg`)"
                      data-holder-rendered="true"
                    />
                    <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->
                  </a>
                  <vue-easy-lightbox
                    :visible="visible"
                    :imgs="imgs"
                    @hide="handleHide"
                  ></vue-easy-lightbox>
                 </div>
                  <div class="mt-2">
                    <div class="mt-3">
                      <i class="fas fa-fan ml-2 mr-1"></i> Immatriculation :
                      <span
                        >AL 2206</span
                      >
                    </div>
                    <div class="mt-1">
                      <i class="fas fa-calendar text-danger ml-2 mr-1"></i> Date départ :
                      <span
                        >22 Avril</span
                      >
                    </div>
                    <div class="mt-1">
                      <i class="fas fa-calendar text-primary ml-2 mr-1"></i> Date retour :
                      <span
                        >25 Avril</span
                      >
                    </div>
                    <div class="mt-1">
                      <i class="mdi mdi-cash ml-2 mr-1"></i> Prix :
                      <span class="badge badge-success py-2"
                        >26000 FCFA</span
                      >
                    </div>
                    <div class="mt-1">
                      <i class="mdi mdi-cash ml-2 mr-1"></i> Kilométrage au compteur lors du retour :
                      <span class="badge badge-danger py-2"
                        >26000 KM</span
                      >
                    </div>
                    <div class="col-md-12 mt-5">
                        observations
                    </div>
                  </div>
                </div>
              </div>
              <span class="vertical-line"></span>
              
              
              <div>
                <div class="row">
                  Locataire :<a class="image-popup-vertical-fit ml-5" @click="showImage">
                    <img
                      style="cursor: pointer; width:50px;"
                      class="avatar avatar-15 rounded-circle"
                      src="require(`@/assets/small/img-1.jpg`)"
                      data-holder-rendered="true"
                    />
                  </a>
                  <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->

                  <div class="ml-3">
                    nom & Prénom
                  </div>
                </div>
                
                <div class="row" style="margin-top:105%;">
                  Chauffeur :<a class="image-popup-vertical-fit ml-5" @click="showImage">
                    <img
                      style="cursor: pointer; width:50px;"
                      class="avatar avatar-15 rounded-circle"
                      src="require(`@/assets/small/img-1.jpg`)"
                      data-holder-rendered="true"
                    />
                  </a>
                  <!-- <img class="img-time-matters" :src="require(`../assets/time-comparison-${locale}.png`)"> -->

                  <div class="ml-3">
                    nom & Prénom
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <GmapMap
              :center="startLocation"
              :zoom="10"
              map-type-id="terrain"
              style="width: 100%; height: 400px"
            >
              <gmap-info-window
                :options="infoOptions"
                :position="infoPosition"
                :opened="infoOpened"
                @closeclick="infoOpened = false"
              >
                {{ infoContent }}
              </gmap-info-window>
              <GmapMarker
                :key="index"
                v-for="(item, index) in coordinates"
                :position="getPosition(item)"
                :clickable="true"
                :draggable="true"
                @click="toggleInfo(item, index)"
              />
            </GmapMap>
          </div>
        </div>
      </div> -->
    </div>
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
.vertical-line {
  border-left: 2px solid #000;
  height: 430px;
  margin: 0 20px;
}
</style>
